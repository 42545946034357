import React from 'react';
import { Link } from 'react-router-dom';

const BackButton: React.FC = () => {
  return (
    <Link to="/" className="back-button">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
      </svg>
    </Link>
  );
};

export default BackButton;
